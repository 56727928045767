<template>
  <div class="mangerPage">
    <div class="main">
      <div class="top_view">
        <a-button type="primary" class="addBtn" @click="exportData">
          导出
        </a-button>
        <div>
          <a-input-search
            class="search_view"
            v-model="searchValue"
            placeholder="请输入序列号或质保编号查询"
            enter-button
            allow-clear
            @search="onSearchBanner"
          />
        </div>
      </div>
      <a-table
        :columns="columns"
        :data-source="tableData"
        bordered
        :scroll="{ x: 1400 }"
        :pagination="false"
        :rowKey="record => record.bannerId"
        :loading="tableLoad"
      >
        <template slot="showStatus" slot-scope="text, record">
          <template v-if="record.bindingStatus == 2">
            已解绑
          </template>
          <template v-if="record.bindingStatus == 1">
            {{ record.showActiveStatus }}
          </template>
        </template>
        <template slot="action" slot-scope="text, record">
          <template v-if="record.bindingStatus == 1">
            <a-popconfirm
              title="确定解绑吗？"
              ok-text="确定"
              cancel-text="取消"
              @confirm="RemoveBindingStatus(record)"
            >
              <a-button class="pointer">
                解绑
              </a-button>
            </a-popconfirm>
          </template>

          <!-- <template v-if="record.bindingStatus == 1 && record.approveStatus == 0">
            <a-button class="pointer" @click="approveReject(record)">拒绝</a-button>
          </template> -->

          <template
            v-if="
              !record.ifLocalMaintenance &&
                record.model &&
                record.bindingStatus == 1
            "
          >
            <a-button class="pointer" @click="addWatch(record)">
              完善腕表数据
            </a-button>
          </template>

          <!--<template v-if="record.bindingStatus == 1 && record.approveStatus == 1 && record.activeStatus == 0">
            <a-button class="pointer" @click="activeCard(record)">激活保卡</a-button>
          </template>

          <template v-if="record.bindingStatus == 1 && record.approveStatus == 1 && record.activeStatus == 1">
            <a-button class="pointer" @click="extendCard(record)">延长质保</a-button>
          </template> -->
        </template>
      </a-table>
      <div class="page_warp">
        <a-pagination
          class="pagination"
          v-model="page.current"
          :total="page.total"
          show-less-items
          :defaultPageSize="page.size"
          @change="pageCurrentChange"
        />
      </div>
    </div>
    <!-- 拒绝弹出框 -->
    <a-modal
      title="提示"
      :visible="visible"
      okText="确定"
      cancelText="取消"
      @ok="handleOk(formData.watchRegisterId)"
      @cancel="handleCancel"
    >
      <a-form-model
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 18 }"
        :model="formData"
        ref="userForm"
        :rules="rules"
      >
        <a-form-model-item label="原因" prop="rejectReason">
          <a-select v-model="formData.rejectReason" placeholder="请选择">
            <a-select-option :value="1">
              序列号不存在
            </a-select-option>
            <a-select-option :value="2">
              质保编号不存在
            </a-select-option>
          </a-select>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
    <!-- 激活保卡弹出框 -->
    <a-modal
      title="激活保卡"
      :visible="activeVisible"
      okText="确定"
      cancelText="取消"
      @ok="activeOk(form.watchRegisterId)"
      @cancel="activeCancel"
    >
      <a-form-model
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 18 }"
        :model="form"
        ref="userForm"
        :rules="rules"
      >
        <a-form-model-item label="激活日期" prop="activeDate">
          <div>
            <a-date-picker
              format="YYYY-MM-DD"
              @change="onChange"
              placeholder="请选择"
              v-model="form.activeDate"
            >
            </a-date-picker>
          </div>
          <p style="font-size:10px;line-height:15px;">
            激活日期，即腕表激活的起始日期。选择激活日期并确认后，列表中的质保过期时间会根据所选择的激活日期自动显示为2年后。
          </p>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
    <!-- 延长质保弹出框 -->
    <a-modal
      title="延长质保"
      :visible="extendVisible"
      okText="确定"
      cancelText="取消"
      @ok="extendOk(extendForm.watchRegisterId)"
      @cancel="extendCancel"
    >
      <a-form-model
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 18 }"
        :model="extendForm"
        ref="userForm"
        :rules="rules"
      >
        <a-form-model-item label="质保过期时间" prop="extendDate">
          <div>
            <a-date-picker
              format="YYYY-MM-DD"
              @change="extendOnChange"
              placeholder="请选择"
              v-model="extendForm.extendDate"
            >
            </a-date-picker>
          </div>
          <p style="font-size:10px;line-height:15px;">
            质保过期时间，即腕表腕表质保过期的终止时间。选择质保过期时间并确认后，列表中的质保过期时间会进行更新。
          </p>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
import API from "@/request";
import moment from "moment";
import exportExcelMethod from "@/request/http";
export default {
  data() {
    return {
      ...this.data()
    };
  },
  methods: {
    data() {
      return {
        visible: false, //拒绝弹框
        activeVisible: false, //激活保卡弹框
        extendVisible: false, //延长保卡弹框
        loading: false, // 封面图上传加载效果控制字段
        tableLoad: false, // 表格数据加载效果
        isSubmitInfo: false, //点击新建可能会重复提交数据

        rules: {
          // 表单验证规则
          rejectReason: [
            {
              required: true,
              message: "请选择拒绝原因",
              trigger: "blur"
            }
          ],
          activeDate: [
            {
              required: true,
              message: "请选择激活日期",
              trigger: "blur"
            }
          ],
          extendDate: [
            {
              required: true,
              message: "请选择质保过期日期",
              trigger: "blur"
            }
          ]
        },

        formData: {
          // 拒绝表单数据
          rejectReason: "", //解绑腕表原因
          watchRegisterId: ""
        },
        form: {
          // 激活保卡表单数据
          activeDate: "", //解绑腕表原因
          watchRegisterId: ""
        },
        extendForm: {
          // 激活保卡表单数据
          extendDate: "", //解绑腕表原因
          watchRegisterId: ""
        },
        page: {
          // 表格分页相关
          total: 0,
          current: 1,
          size: 10
        },
        tableData: [], // 表格数据
        changStatus: "", // 表格右上类型筛选绑定字段
        searchValue: "",
        statusValue: "",
        typeValue: 0,
        columns: [
          {
            title: "姓名",
            dataIndex: "fullName"
          },
          {
            title: "手机号",
            dataIndex: "phone"
          },
          {
            title: "腕表序列号",
            dataIndex: "serialNo"
          },
          {
            title: "质保编号",
            width: 160,
            dataIndex: "qualityAssuranceNo"
          },
          {
            title: "腕表型号",
            width: 180,
            dataIndex: "model"
          },
          {
            title: "状态",
            width: 80,
            dataIndex: "showStatus",
            scopedSlots: { customRender: "showStatus" }
          },
          {
            title: "登记日期",
            dataIndex: "registerTime"
          },
          {
            title: "激活日期",
            dataIndex: "activeTime"
          },
          {
            title: "质保过期时间",
            dataIndex: "qualityAssuranceExpireTime"
          },
          {
            title: "操作",
            dataIndex: "aaa",
            width: "250px",
            scopedSlots: { customRender: "action" }
          }
        ],
        isAdd: false, // 控制新增表单弹窗控制字段
        header_title: "新增",
        isShowPriview: false
      };
    },
    resetData() {
      Object.assign(this.$data, this.data());
    },
    pageCurrentChange(val) {
      // 分页页码变化
      this.page.current = val;
      this.getList();
    },
    goUrl(userId) {
      // this.$router.replace('/bannerLevel')
      this.$router.push({ path: "/watchBox", query: { userId: userId } });
    },
    /**
     * 导出数据
     */
    async exportData() {
      if (this.page.total > 0) {
        let exObj = {
          methods: "GET",
          url:
            process.env.VUE_APP_BASE_API +
            "/watch-register/exportExcel?keyword=" +
            this.searchValue,
          fileName: "真力时延保小程序腕表登记信息"
        };
        exportExcelMethod.exportExcelMethod(exObj);
      } else {
        this.$message.success("无数据可导出");
      }
    },
    //状态搜索
    changStatusValue(value) {
      this.statusValue = value;
      this.getList();
    },

    //搜索
    onSearchBanner(value) {
      this.getList();
    },
    /**
     * 弹出框提交数据
     */
    async submitData(val) {
      let validateJump;
      this.formData.materialType = 0;
      console.log(this.formData);

      // 组装提交数据
      this.$refs.contentForm.validate(async valid => {
        if (valid) {
          let res;

          let message;

          if (!this.formData.bannerId) {
            if (this.isSubmitInfo) {
              // this.$message.warning("新增中，请勿重复提交！");
              return;
            }
            this.isSubmitInfo = true;
            res = await API.addBanner(this.formData).catch(err => {
              this.isSubmitInfo = false;
            });
            message = "新增成功";
          } else {
            res = await API.editBanner(this.formData);
            message = "修改成功";
          }
          if (res.data.code === "0000") {
            this.$message.success(message);
            this.isAdd = false;
            this.formData = {
              materials: "",
              jump: "",
              location: this.typeValue,
              name: "",
              sort: "",
              type: 1
            };
            this.coverList = [];
            this.getList();
          }
        }
      });
    },
    async approveReject(key) {
      this.formData = {
        watchRegisterId: key.watchRegisterId
      };
      this.visible = true;
    },
    async addWatch(key) {
      this.$router.push({ path: "/watchMainData" });
    },

    async approvePass(record) {
      if (record.model) {
        let res = await API.UpdateApproveStatus({
          approveStatus: 1,
          watchRegisterId: record.watchRegisterId
        });
        if (res.data.code === "0000") {
          this.$message.success("验证同意成功");
          this.getList();
        }
      } else {
        alert(
          "若腕表型号存在，可前往“腕表主数据管理”模块中将腕表序列号维护在对应型号的腕表信息中"
        );
      }
    },

    async activeCard(key) {
      this.form = {
        watchRegisterId: key.watchRegisterId
      };
      this.activeVisible = true;
    },

    handleOk(watchRegisterId) {
      this.$refs.userForm.validate(async valid => {
        if (valid) {
          let res;
          res = await API.UpdateApproveStatus({
            approveStatus: 2,
            rejectReason: this.formData.rejectReason,
            watchRegisterId: watchRegisterId
          });
          if (res.data.code === "0000") {
            this.$message.success("验证拒绝成功");
            this.visible = false;
            this.formData = {
              rejectReason: ""
            };
          }
          await this.getList();
        }
      });
    },

    activeOk(watchRegisterId) {
      this.$refs.userForm.validate(async valid => {
        if (valid) {
          let res;
          res = await API.updateActivityStatus({
            activeStatus: 1,
            updateTime: moment(this.form.activeDate).valueOf(),
            watchRegisterId: watchRegisterId
          });
          if (res.data.code === "0000") {
            this.$message.success("激活成功");
            this.activeVisible = false;
            this.form = {
              activeDate: ""
            };
          }

          await this.getList();
        }
      });
    },

    activeCancel() {
      this.form = {
        activeDate: ""
      };
      this.activeVisible = false;
    },

    /**
     * 时间选择
     */
    onChange(dates) {
      this.form.activeDate = dates.format("YYYY-MM-DD");
    },

    extendOk(watchRegisterId) {
      this.$refs.userForm.validate(async valid => {
        if (valid) {
          let res;
          res = await API.updateActivityStatus({
            activeStatus: 2,
            updateTime: moment(this.extendForm.extendDate).valueOf(),
            watchRegisterId: watchRegisterId
          });
          if (res.data.code === "0000") {
            this.$message.success("延长质保成功");
            this.extendVisible = false;
            this.extendForm = {
              extendDate: ""
            };
          }

          await this.getList();
        }
      });
    },

    extendCancel() {
      this.extendForm = {
        extendDate: ""
      };
      this.extendVisible = false;
    },

    /**
     * 延长质保时间选择
     */
    extendOnChange(dates) {
      this.extendForm.extendDate = dates.format("YYYY-MM-DD");
    },

    async extendCard(key) {
      this.extendForm = {
        watchRegisterId: key.watchRegisterId
      };
      this.extendVisible = true;
    },

    handleCancel() {
      if (this.$refs.userForm != undefined) {
        this.$refs.userForm.resetFields();
      }
      this.formData = {
        account: "",
        userName: "",
        passWord: "",
        userId: ""
      };
      this.visible = false;
    },

    handleChange() {},
    async UpdateApprovePass(record) {
      let res = await API.UpdateApproveStatus({
        approveStatus: 1,
        watchRegisterId: record.watchRegisterId
      });
      if (res.data.code === "0000") {
        this.getList();
      }
    },
    async RemoveBindingStatus(record) {
      let res = await API.RemoveBindingStatus({
        watchRegisterId: record.watchRegisterId
      });
      if (res.data.code === "0000") {
        this.getList();
      }
    },
    // 获取表格数据
    async getList(
      keyword = this.searchValue,
      current = keyword ? 1 : this.page.current,
      size = this.page.size
    ) {
      this.tableLoad = true;
      let res = await API.getWatchRegisterList({ current, keyword, size });
      if (res.data.code === "0000") {
        let data = res.data.data;
        this.tableData = data.records;
        this.tableLoad = false;
        this.page = {
          // 设置分页信息
          current: data.current,
          total: data.total,
          size: data.size
        };
      }
    }
  },
  mounted() {
    //this.getBannerArea()
    this.getList(); // 请求表格数据
  }
};
</script>

<style lang="less" scoped>
/deep/.has-error .ant-upload.ant-upload-select-picture-card {
  border: 1px dashed #f5222d !important;
}
.pointer {
  margin-right: 8px;
}
.loading_warp {
  display: flex;
  flex-flow: column;
  justify-content: center;
  .loading_text {
    text-align: center;
  }
}
.picView {
  max-width: 350px;
  max-height: 200px;
}
.videoPlater {
  outline: none;
  max-width: 300px;
  max-height: 200px;
}
.pageHead {
  background: white;
}

.main {
  margin: 24px;
  background: #ffffff;
  padding: 24px;
}

.newLotsBtn {
  margin-bottom: 16px;
}
.modal_content {
  max-height: 600px;
  overflow: auto;
}
.search_View {
  width: 230px;
}

.tab_top_view {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-end;
}
.content_warp {
  display: flex;
  justify-content: space-between;
  .block_warp {
    margin-top: 10px;
    // border: 1px solid #cccccc;
    background: #ffffff;
    border-radius: 10px;
    width: 100%;
    // padding: 15px 10px;
    display: flex;
    .block_left {
      flex: 1;
    }
    .block_right {
      box-sizing: border-box;
      padding-left: 25px;
      display: flex;
      //align-items: flex-end;
    }
  }
}

.top_view {
  display: flex;
  justify-content: space-between;
  height: 48px;
}
.search_view {
  width: 280px;
  margin-right: 20px;
}
.page_warp {
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
}
.titleDiv {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  span {
    font-size: 16px;
    color: #333333;
    font-weight: 600;
    margin-right: 10px;
  }
  .titleInput {
    width: 307px;
    margin-right: 10px;
  }
}
</style>
